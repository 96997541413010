import NotificationsService from "@/services/notifications-service";
import { handleError } from "@/utils/error";
import { runWithDelay } from "@/utils/helpers";
import { defineStore } from "pinia";

interface LocalNotification {
  id: number;
  isVisible: boolean;
  message: string;
  isError: boolean;
}

const notificationsService = new NotificationsService("notifications");

export const useLocalNotificationsStore = defineStore("localNotifications", {
  state: () => ({
    notifications: [] as LocalNotification[],
  }),
  actions: {
    addNotification(
      v: { message: string; isError: boolean },
      dontHide?: boolean,
    ) {
      const id = new Date().getTime();
      this.notifications = [
        ...this.notifications,
        { ...v, id, isVisible: false },
      ];

      runWithDelay(() => {
        this.notifications = this.notifications.map((n) =>
          n.id === id ? { ...n, isVisible: true } : n,
        );
      }, 200);

      if (!v.isError && !dontHide) {
        runWithDelay(() => this.hideNotification(id), 4000);
      }
    },
    hideNotification(id: number) {
      this.notifications = this.notifications.map((n) =>
        n.id === id ? { ...n, isVisible: false } : n,
      );

      runWithDelay(() => {
        this.notifications = this.notifications.filter((n) => n.id !== id);
      }, 200);
    },
    subscribeNews(email: string) {
      try {
        notificationsService.subscribeNews(email);
        this.addNotification({
          message: "Successful subscription with " + email,
          isError: false,
        });
      } catch (error) {
        console.error(error);
        this.addNotification({
          message: "Failed to subscribe with " + email,
          isError: true,
        });
      }
    },
  },
});
