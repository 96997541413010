import { handleError } from "@/utils/error";
import BaseService from "./base-service";

class NotificationsService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async subscribeNews(email: string): Promise<void> {
    try {
      return await this.post(`${this.getServiceContext}/subscribe`, { email });
    } catch (error) {
      throw handleError(error);
    }
  }
}

export default NotificationsService;
